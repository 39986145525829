<template>
  <div class="col-12 col-sm-12 col-md-4 wrapper d-flex flex-column">
    <div class="d-flex">
      <h1 class="title mt-0 fs-b fs-2500 color-pr finalisation-titre">
        {{ filAriane }}
      </h1>
    </div>

    <div class="row container" v-if="success">
      <div class="col-sm-4">
        <img
          src="../assets/img/mail.svg"
          class="mt-4 img-mobile"
          aria-hidden="true"
          alt="icone d'un mail"
        />
      </div>
      <div class="col-sm-8 mt-5">
        <span class="title-bloc"
          >Votre adresse courriel a bien été validée.</span
        ><br />

        <div class="form-button mt-5">
          <button class="col btn" @click="fermer()">Fermer</button>
        </div>
      </div>
    </div>
    <div class="row container" v-if="!success">
      <div class="col-sm-4">
        <img
          src="../assets/img/non-accessible.svg"
          class="mt-4 img-echec-mobile"
          aria-hidden="true"
          alt="image d'un ordinateur avec sur l'écran un panneau sens interdit"
        />
      </div>
      <div class="col-sm-8 mt-5">
        <span class="title-bloc"
          >Le lien a expiré. Veuillez renouveler votre demande.</span
        ><br />

        <div class="form-button-echec">
          <button class="col btn-goToHome button-echec" @click="goToHome()">
            Page d'accueil
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      success: false
    };
  },
  mounted() {
    this.checkFinalisation();
  },
  methods: {
    checkFinalisation() {
      const code = this.$route.query.confirmation;
      const compte = {
        code: code,
        verifiedChannel: {
          type: 'EMAIL',
          claim: 'http://wso2.org/claims/emailaddress'
        },
        properties: []
      };
      this.$compteUtilisateurService
        .validationCode(compte)
        .then((res) => {
          console.log(res);
          if (res.status === 200 && res.data.resultat == 202) {
            this.success = true;
          } else {
            this.success = false;
            this.filAriane = 'Réinitialisation du mot de passe';
          }
        })
        .catch((err) => {
          console.error('erreur', err);
        });
    },

    renvoyer() {
      const email = this.$route.query.email;
      const compte = {
        user: {
          username: email,
          realm: ''
        },
        properties: []
      };
      this.$compteUtilisateurService
        .resendCode(compte)
        .then((res) => {
          if (res.status === 200 && res.data.resultat === 201) {
            const html = `<p class="fs-875">Nous vous avons envoyé un courriel de confirmation.<br><br>
Merci de le consulter afin de finaliser votre inscription.</p>`;
            this.setContent({ html, action: 'info' });
            this.showPopup();
            this.emitter.once('popup', (payload) => {
              if (payload === 'ok' || payload === 'hide') {
                this.$router.push({ name: 'Mon compte' }).catch(() => {});
              }
            });
          } else {
            console.log('erreur envoi courriel confirmation');
          }
        })
        .catch((err) => {
          console.error('erreur', err);
        });
    },

    goToHome() {
      this.$router.push({ name: 'Mon compte' });
    },

    fermer() {
      this.$router.push({ name: 'Mon compte' });
    },
    ...mapActions(['showPopup', 'setContent'])
  }
};
</script>

<style lang="scss" scoped>
h1 {
  color: $base-color;
  display: flex;
}

.finalisation-titre {
  margin-left: 18rem;
}

.bloc {
  width: auto;
  display: flex;
  padding: 1rem;
}

.container {
  width: 65%;
  padding: 3%;
  padding-right: 2%;
  border: 2px solid #dddddd;
  margin: 2%;
  margin-left: 24%;
}

.title-bloc {
  font-size: 20px;
}

.btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  width: 100%;
  margin-left: 1rem;
}

.btn:hover {
  background-color: $base-color;
}

.btn-goToHome {
  background: #ffffff;
  color: $base-color;
  border: $base-color solid 1px;
  font-size: 14px;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.form-button {
  display: flex;
  width: 35%;
  padding-top: 0.5rem;
  height: 3.5rem;
  margin-left: 40%;
}

.form-button-echec {
  display: flex;
  width: 100%;
  padding-top: 0.5rem;
  height: 3.5rem;
}

.img-echec-mobile {
  width: 90%;
}

@media only screen and (max-width: 760px) {
  .container {
    min-height: 26rem;
  }

  .form-button {
    width: 50%;
    margin-left: 15%;
  }

  .form-button-echec {
    display: block;
    width: 90%;
    padding-top: 0rem;
    height: 3.5rem;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .button-echec {
    margin-left: 0rem;
    margin-top: 0.5rem;
    height: 2rem;
  }

  .title-bloc {
    font-size: 15px;
  }

  .img-mobile {
    width: 90%;
    margin-right: 1rem;
  }

  .finalisation-titre {
    margin-left: 0rem;
  }
}
</style>
